import React, { useEffect, useState } from 'react';

import Img from 'gatsby-image';

import styled from 'styled-components';
import {
  compose,
  spacing,
  flexbox,
  sizing,
  palette,
  positions,
  borders,
  display,
} from '@material-ui/system';

import { useTransition, animated } from 'react-spring';

import { useStaticQuery, graphql, Link } from 'gatsby';

import Navigation from '../Navigation';

import { getGlobal, getScrollY, getViewportHeight } from './utils';

interface Props {
  height: number;
  maxWidth: number;
  pinStart: string;
}

export default function Header({ pinStart, height, maxWidth }: Props) {
  const [position, setPosition] = useState('absolute');
  const [mustShow, show] = useState(true);

  const { logo } = useStaticQuery(query);

  const transitions = useTransition(mustShow, null, {
    from: {
      transform: `translate3d(0, ${position === 'fixed' ? -height : 0}px, 0)`,
      opacity: 1,
    },
    enter: { transform: `translate3d(0, 0, 0)`, opacity: 1 },
    leave: {
      transform: `translate3d(0, ${-height}px, 0)`,
      opacity: 0,
    },
  });

  useEffect(() => {
    if (pinStart !== '0') {
      getGlobal().addEventListener('scroll', handleScroll);
    } else {
      setPosition('fixed');
      getGlobal().removeEventListener('scroll', handleScroll);
    }

    return () => {
      getGlobal().removeEventListener('scroll', handleScroll);
    };
  }, [pinStart, height]);

  function handleScroll() {
    const heroHeight = getViewportHeight();

    if (getScrollY() >= heroHeight) {
      setPosition('fixed');
      show(true);
    } else {
      setPosition('absolute');
      if (getScrollY() > height && getScrollY() < heroHeight) {
        show(false);
      } else {
        show(true);
      }
    }
  }

  return (
    <>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <StyledHeader
              alignItems="center"
              bgcolor={
                position === 'fixed' ? 'background.default' : 'transparent'
              }
              color={position === 'fixed' ? 'text.primary' : 'common.white'}
              display="flex"
              key={key}
              minHeight={height}
              position={position}
              style={props}
              top={0}
              width="100%"
              zIndex={2}
            >
              <Container
                alignItems="center"
                display="flex"
                flex="1 1 100%"
                flexDirection={{ xs: 'row-reverse', md: 'column', lg: 'row' }}
                justifyContent={{
                  xs: 'flex-end',
                  md: 'space-between',
                }}
                maxWidth={maxWidth}
                mx="auto"
                px={{ xs: 1 / 2, md: 0 }}
              >
                <LogoLink to="/">
                  <StyledLogo fixed={logo.childImageSharp.fixed} />
                </LogoLink>
                <Container
                  display="flex"
                  flex="1 0 auto"
                  justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
                  pt={{ xs: 0, md: 2, lg: 0 }}
                >
                  <Navigation />
                </Container>
              </Container>
            </StyledHeader>
          ),
      )}
    </>
  );
}

const query = graphql`
  query {
    logo: file(name: { regex: "/logo/" }) {
      publicURL
      childImageSharp {
        fixed(width: 96) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

const StyledHeader = styled(
  ({ borderBottom, alignItems, minHeight, zIndex, ...props }) => (
    <animated.header {...props} />
  ),
)`
  ${compose(
    sizing,
    positions,
    palette,
    borders,
    display,
    flexbox,
  )}
  border-bottom: ${({ theme, position }) =>
    position === 'fixed' ? `solid 1px ${theme.palette.divider}` : '0px'};
`;

const Container = styled.div`
  ${compose(
    sizing,
    spacing,
    flexbox,
    display,
  )}
`;

const LogoLink = styled(Link)`
  display: flex;
`;

const StyledLogo = styled(Img)``;
