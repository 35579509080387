import React from 'react';
import { Link } from 'gatsby';

import styled from 'styled-components';
import { display, palette } from '@material-ui/system';
import { Typography } from '@material-ui/core';

import { useAuthentication } from '../../Authentication';

import SectionTitle from './SectionTitle';
import Panel from './Panel';
import Item from './Item';

interface Props {
  onClose?: () => void;
}

export default function Account({ onClose = () => {} }: Props) {
  const { logout } = useAuthentication();
  return (
    <Container display="grid">
      <Panel css={{ gridColumn: '1' }}>
        <SectionTitle m={1 / 2}>{'Informations personnelles'}</SectionTitle>
        <Item component={Link} onClick={() => onClose()} to={'/account'}>
          <Text color="textPrimary" variant="subtitle2">
            {'Votre profil'}
          </Text>
          <Text color="textSecondary" variant="body2">
            {'Gérez vos données personnelles'}
          </Text>
        </Item>
        <Item
          component={Link}
          onClick={() => onClose()}
          to={'/account/settings'}
        >
          <Text color="textPrimary" variant="subtitle2">
            {'Options et préférences'}
          </Text>
          <Text color="textSecondary" variant="body2">
            {
              'Gérez les paramètres de votre compte utilisateur ansi que vos options'
            }
          </Text>
        </Item>
        <Item
          component={Link}
          onClick={() => onClose()}
          to={'/account/billing'}
        >
          <Text color="textPrimary" variant="subtitle2">
            {'Informations de facturation'}
          </Text>
          <Text color="textSecondary" variant="body2">
            {'Gérez vos informations de facturation'}
          </Text>
        </Item>
        <Item onClick={logout}>
          <Text color="textPrimary" variant="subtitle2">
            {'Déconnexion'}
          </Text>
          <Text color="textSecondary" variant="body2">
            {'Fermez votre session'}
          </Text>
        </Item>
      </Panel>
    </Container>
  );
}

const Container = styled.div`
  ${display}
  grid-template-columns: '1fr';
`;

const Text = styled(Typography)`
  ${palette}
`;
