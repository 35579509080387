import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import { useQuery, useMutation, useApolloClient } from '@apollo/react-hooks';

import styled from 'styled-components';
import { Tabs, Tab } from '@material-ui/core';
import { palette, PaletteProps } from '@material-ui/system';

import { LAYOUT_STATE_QUERY, CHANGE_COLOR_SCHEME } from '../Layout';
import { sortByOrderProperty } from '../../utils';

import { Product } from '../../types/training';

import ProductTab from './ProductTab';

import {
  TOGGLE_PRODUCT,
  FILTERS_PRODUCTS_QUERY,
  filtersResolvers,
} from './localState';

const TabsContainer = styled.div<PaletteProps>`
  ${palette}
  display: flex;
  justify-content: center;
  transition: ${({ theme }) =>
    theme.transitions.create('background-color', {
      duration: theme.transitions.duration.short,
    })};
`;

const StyledTabs = styled(({ maxWidth, ...props }) => <Tabs {...props} />)`
  flex: 1 1 100%;
  max-width: ${({ maxWidth }) => maxWidth}px;
`;

interface ProductQuery {
  prismic: {
    allProducts: {
      edges: Product[];
    };
  };
}

export default function ProductTabs() {
  const { prismic } = useStaticQuery<ProductQuery>(productQuery);
  const client = useApolloClient();

  const { data: layoutData, loading: layoutLoading } = useQuery(
    LAYOUT_STATE_QUERY,
  );
  const { data: filterData, loading: filterLoading } = useQuery(
    FILTERS_PRODUCTS_QUERY,
  );

  const [changeColorScheme] = useMutation(CHANGE_COLOR_SCHEME);
  const [selectProduct] = useMutation(TOGGLE_PRODUCT);

  client.addResolvers(filtersResolvers);

  if (!layoutData || !filterData || layoutLoading || filterLoading) {
    return <>{'Loading Products infos'}</>;
  }

  return (
    <TabsContainer bgcolor={`${layoutData.layout.colorScheme}.main`}>
      <StyledTabs
        centered
        maxWidth={layoutData.layout.maxWidth}
        onChange={(_: React.MouseEvent, product: string) => {
          const currentProduct = prismic.allProducts.edges.find(
            ({ node }) => node.tms_reference === product,
          );
          if (currentProduct) {
            changeColorScheme({
              variables: {
                colorScheme: currentProduct.node.color || 'yellow',
              },
            });
            selectProduct({ variables: { product } });
          }
        }}
        TabIndicatorProps={{ style: { backgroundColor: '#414141' } }}
        value={filterData.filter.product}
        variant="fullWidth"
      >
        {prismic.allProducts.edges
          .sort(sortByOrderProperty)
          .map(({ node: { name, title, tms_reference } }) => (
            <Tab
              component={ProductTab}
              disableRipple
              key={tms_reference}
              name={name}
              title={title}
              value={tms_reference}
            />
          ))}
      </StyledTabs>
    </TabsContainer>
  );
}

const productQuery = graphql`
  query {
    prismic {
      allProducts {
        edges {
          node {
            name
            title
            tms_reference
            order
            color
          }
        }
      }
    }
  }
`;
