import React from 'react';

import { Popover } from '@material-ui/core';

interface CloseFunction {
  (): void;
}

interface Props {
  anchorEl: HTMLElement;
  component: React.ComponentType<{ onClose?: CloseFunction }>;
  onClose: CloseFunction;
  open: boolean;
}

export default function Menu({
  open,
  component: Component,
  onClose,
  anchorEl,
}: Props) {
  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      onClose={onClose}
      open={open}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Component onClose={onClose} />
    </Popover>
  );
}
