// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-catalog-tsx": () => import("../src/templates/Catalog.tsx" /* webpackChunkName: "component---src-templates-catalog-tsx" */),
  "component---src-templates-page-tsx": () => import("../src/templates/Page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-service-tsx": () => import("../src/templates/Service.tsx" /* webpackChunkName: "component---src-templates-service-tsx" */),
  "component---src-templates-training-template-tsx": () => import("../src/templates/TrainingTemplate.tsx" /* webpackChunkName: "component---src-templates-training-template-tsx" */),
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-tsx": () => import("../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-callback-tsx": () => import("../src/pages/callback.tsx" /* webpackChunkName: "component---src-pages-callback-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-sign-up-tsx": () => import("../src/pages/sign-up.tsx" /* webpackChunkName: "component---src-pages-sign-up-tsx" */)
}

