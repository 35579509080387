import color from 'color';

import { palette } from '../colors';
import { typography } from '../typography';

const fieldBg = color('rgb(246, 246, 241)');

export default {
  MuiButton: {
    containedPrimary: {
      '&:hover': {
        backgroundColor: color(palette.primary.main)
          .darken(0.2)
          .rgb()
          .toString(),
      },
    },
  },
  MuiFormLabel: {
    root: {
      '&$focused': {
        color: palette.text.primary,
      },
    },
  },
  MuiFilledInput: {
    root: {
      borderTopRightRadius: 3,
      borderBottomRightRadius: 3,
      borderBottomLeftRadius: 3,
      borderTopLeftRadius: 3,
      backgroundColor: fieldBg.rgb().toString(),
      '&:hover': {
        backgroundColor: fieldBg
          .darken(0.05)
          .rgb()
          .toString(),
      },
      '&$focused': {
        backgroundColor: fieldBg
          .darken(0.05)
          .rgb()
          .toString(),
      },
    },
  },
  MuiFormHelperText: {
    contained: {
      marginLeft: 0,
      fontSize: typography.body2.fontSize,
    },
  },
};
