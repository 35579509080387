import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { setContext } from 'apollo-link-context';
import fetch from 'unfetch';

const cache = new InMemoryCache();

const httpLink = new BatchHttpLink({
  uri: process.env.GRAPHQL_ENDPOINT || 'https://api.crpm.ch/graphql',
  fetch,
});

const authLink = setContext((_, { headers, accessToken }) => ({
  headers: {
    ...headers,
    authorization: accessToken ? `Bearer ${accessToken}` : undefined,
  },
}));

export default new ApolloClient({
  cache,
  link: authLink.concat(httpLink),
  resolvers: {},
});
