import React from 'react';

import styled from 'styled-components';
import {
  compose,
  borders,
  spacing,
  palette,
  BordersProps,
  SpacingProps,
  PaletteProps,
} from '@material-ui/system';
import { Typography } from '@material-ui/core';

const Title = styled(({ borderBottom, borderColor, ...props }) => (
  <Typography {...props} />
))`
  ${compose(
    borders,
    spacing,
    palette,
  )}
  text-transform: uppercase;
`;

interface Props extends BordersProps, SpacingProps, PaletteProps {
  children: React.ReactNode;
}

export default function SectionTitle({ children, ...props }: Props) {
  return (
    <Title
      borderBottom="solid 1px"
      borderColor="divider"
      fontWeight="demiBold"
      m={1}
      pb={2}
      variant="body2"
      {...props}
    >
      {children}
    </Title>
  );
}
