import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import { useQuery } from '@apollo/react-hooks';

import styled from 'styled-components';

import {
  compose,
  spacing,
  sizing,
  palette,
  SpacingProps,
} from '@material-ui/system';

import { Typography } from '@material-ui/core';

import { FluidImage } from '../Trainings/types';

import { LAYOUT_STATE_QUERY } from '../Layout';

interface Props {
  align?: string;
  callToAction?: React.ReactNode;
  height?: string;
  image: FluidImage;
  maxWidth: number;
  subtitle?: string;
  title?: string;
  variant: string;
}

export default function HeroImage({
  image,
  callToAction = null,
  title = '',
  subtitle = '',
  height,
  align = 'left',
}: Props) {
  const { data } = useQuery(LAYOUT_STATE_QUERY);
  return (
    <StyledBackgroundImage
      fluid={[
        'linear-gradient(270deg, rgba(0,0,0,0.00) 39%, rgba(0,0,0,0.40) 100%)',
        image.childImageSharp.fluid,
      ]}
      height={height}
    >
      <Overlay
        alignItems={align}
        maxWidth={data.layout.maxWidth}
        px={{ xs: 2, sm: 4, lg: 0 }}
      >
        <Title
          align={align}
          color="inherit"
          maxWidth={{ xs: 1, md: align === 'center' ? 1 : 1 / 2 }}
          mb={subtitle ? 2 : 8}
          variant="h1"
        >
          {title}
        </Title>
        <Subtitle
          align={align}
          color="inherit"
          maxWidth={{ xs: 1, md: align === 'center' ? 1 : 1 / 2 }}
          mb={subtitle ? 8 : 0}
          variant="subtitle1"
        >
          {subtitle}
        </Subtitle>
        {callToAction}
      </Overlay>
    </StyledBackgroundImage>
  );
}

const Overlay = styled.div<
  SpacingProps & { alignItems: string; maxWidth: number }
>`
  ${spacing}
  width: 100%;
  max-width: ${({ maxWidth }) => `${maxWidth}px`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ alignItems }) =>
    alignItems === 'center' ? 'center' : 'flex-start'};
  color: ${({ theme }) => theme.palette.text.inverted};
`;

const StyledBackgroundImage = styled(BackgroundImage)`
  ${sizing}
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled(({ maxWidth, ...props }) => <Typography {...props} />)`
  ${compose(
    palette,
    sizing,
    spacing,
  )}
`;

const Subtitle = styled(({ maxWidth, ...props }) => <Typography {...props} />)`
  ${compose(
    palette,
    sizing,
    spacing,
  )}
`;
