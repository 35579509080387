import React from 'react';
import { useQuery, useApolloClient } from '@apollo/react-hooks';

import { spacing } from '@material-ui/system';
import styled, { createGlobalStyle } from 'styled-components';

import HeroImage from '../HeroImage';

import Header from '../Header';

import '../../assets/avenir-next.css';
import '../../assets/century-gothic.css';
import Footer from '../Footer';

import { FluidImage } from '../Trainings/types';

import { resolvers, LAYOUT_STATE_QUERY } from './localState';

const GlobalStyle = createGlobalStyle<{ variant: string; paddingTop: number }>`
  body {
    padding-top: ${({ variant, paddingTop }) =>
      variant === 'landing' ? 0 : `${paddingTop}px`}
  }
`;

const Main = styled.main`
  ${spacing}
`;

interface Props {
  callToAction?: React.ReactElement;
  children: React.ReactElement | React.ReactElement[];
  headerAlign?: string;
  heroImage?: FluidImage;
  subtitle?: string;
  title?: string;
  variant?: string;
}

export default function Layout({
  children,
  headerAlign,
  heroImage,
  title = '',
  subtitle = '',
  variant = 'default',
  callToAction,
}: Props) {
  const client = useApolloClient();
  client.addResolvers(resolvers);

  const { data, loading } = useQuery(LAYOUT_STATE_QUERY);

  if (loading || !data) {
    return null;
  }

  let imageHeight = '0px';

  if (heroImage) {
    if (variant === 'landing') {
      imageHeight = '100vh';
    } else if (variant === 'small') {
      imageHeight = '353px';
    } else {
      imageHeight = '656px';
    }
  }

  return (
    <>
      <GlobalStyle paddingTop={data.layout.headerHeight} variant={variant} />
      {heroImage && (
        <HeroImage
          align={headerAlign}
          callToAction={callToAction}
          height={imageHeight}
          image={heroImage}
          maxWidth={data.layout.maxWidth}
          subtitle={subtitle}
          title={title}
          // top={variant === 'landing' ? 0 : data.layout.headerHeight}
          variant={variant}
        />
      )}
      <Header
        height={data.layout.headerHeight}
        maxWidth={data.layout.maxWidth}
        pinStart={variant === 'landing' ? imageHeight : '0'}
      />
      <Main pt={heroImage ? 0 : `${data.layout.headerHeight}px`}>
        {children}
      </Main>
      <Footer />
    </>
  );
}
