import fontSizes from './fontSizes';
import { palette } from './colors';
import fontWeights from './fontWeights';

export const customTypography = {
  h1: {
    margin: 0,
  },
  h2: {
    margin: 0,
  },
  h3: {
    margin: 0,
  },
  h4: {
    margin: 0,
  },
  h5: {
    margin: 0,
  },
  h6: {
    margin: 0,
  },
  subtitle1: {},
  subtitle2: {},
  body1: {
    color: palette.text.secondary,
  },
  body2: {
    color: palette.text.secondary,
  },
  button: {
    textTransform: 'uppercase',
  },
  caption: {
    textTransform: 'uppercase',
  },
  overline: {},
};

export const typography = {
  h1: {
    fontFamily: 'CenturyGothic',
    fontSize: fontSizes[8],
    fontWeight: fontWeights[4],
  },
  h2: {
    fontFamily: 'CenturyGothic',
    fontSize: fontSizes[6],
    fontWeight: fontWeights[4],
  },
  h3: {
    fontFamily: 'CenturyGothic',
    fontSize: fontSizes[5],
    fontWeight: fontWeights[1],
  },
  h4: {
    fontFamily: 'CenturyGothic',
    fontSize: fontSizes[4],
    fontWeight: fontWeights[2],
  },
  h5: {
    fontFamily: 'CenturyGothic',
    fontSize: fontSizes[3],
    fontWeight: fontWeights[3],
  },
  h6: {
    fontFamily: 'CenturyGothic',
    fontSize: fontSizes[3],
    fontWeight: fontWeights[3],
  },
  subtitle1: {
    fontFamily: 'AvenirNext',
    fontSize: fontSizes[3],
    fontWeight: fontWeights[1],
  },
  subtitle2: {
    fontFamily: 'AvenirNext',
    fontSize: fontSizes[2],
    fontWeight: fontWeights[2],
  },
  body1: {
    fontFamily: 'AvenirNext',
    fontSize: fontSizes[2],
    fontWeight: fontWeights[1],
  },
  body2: {
    fontFamily: 'AvenirNext',
    fontSize: fontSizes[1],
    fontWeight: fontWeights[1],
  },
  button: {
    fontFamily: 'AvenirNext',
    fontSize: fontSizes[1],
    fontWeight: fontWeights[2],
  },
  caption: {
    fontFamily: 'AvenirNext',
    fontSize: fontSizes[1],
    fontWeight: fontWeights[3],
  },
  overline: {
    fontFamily: 'AvenirNext',
    fontSize: fontSizes[0],
    fontWeight: fontWeights[1],
  },
};
