import React, { useEffect } from 'react';

import { ApolloProvider } from '@apollo/react-hooks';

import { CssBaseline } from '@material-ui/core';

import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';

import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import theme from '../../styles';

import Auth0 from '../../utils/authServices/Auth0';

import initializeLocalState from '../../utils/initializeLocalState';

import client from '../Apollo/client';
import { AuthenticationProvider } from '../Authentication';

import AppContextProvider from '../AppContext';

interface Props {
  children: React.ReactNode;
}

const service = new Auth0();

export default function RootWrapper({ children }: Props) {
  useEffect(() => {
    initializeLocalState(client);
  }, []);

  return (
    <ApolloProvider client={client}>
      <AuthenticationProvider service={service}>
        <MuiThemeProvider theme={theme}>
          <>
            <CssBaseline />
            <StyledThemeProvider theme={theme}>
              <AppContextProvider>{children}</AppContextProvider>
            </StyledThemeProvider>
          </>
        </MuiThemeProvider>
      </AuthenticationProvider>
    </ApolloProvider>
  );
}
