interface ObjectWithOrderProp {
  order: number;
}

interface NodeWithSortableObject {
  node: ObjectWithOrderProp;
}

export function sortByOrderProperty(
  { node: a }: NodeWithSortableObject,
  { node: b }: NodeWithSortableObject,
): number {
  if (a.order < b.order) {
    return -1;
  } else if (a.order > b.order) {
    return 1;
  }
  return 0;
}

interface SortableObject {
  [key: string]: string;
}

export function sortTrainings(orderBy: string) {
  return (a: SortableObject, b: SortableObject) => {
    if (a[orderBy] < b[orderBy]) {
      return -1;
    } else if (a[orderBy] > b[orderBy]) {
      return 1;
    }
    return 0;
  };
}

interface UtilsFunction {
  (...args: any[]): void;
}

export function debounce<F extends UtilsFunction>(fn: F, wait = 200) {
  let timeout: ReturnType<typeof setTimeout>;
  return (...args: any[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => fn(...args), wait);
  };
}

export function throttle<F extends UtilsFunction>(fn: F, limit: number) {
  let inThrottle: boolean;
  return (...args: any[]) => {
    if (!inThrottle) {
      fn(...args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
}
