import React, { cloneElement } from 'react';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { useQuery } from '@apollo/react-hooks';

import styled from 'styled-components';
import {
  compose,
  palette,
  typography,
  TypographyProps,
  PaletteProps,
} from '@material-ui/system';

import { linkResolver } from '../../utils/prismic';

import { LAYOUT_STATE_QUERY } from '../Layout';

interface Props {
  text:
    | string
    | Array<{
        spans: Array<{
          start: number;
          end: number;
          type: string;
        }>;
        text: string;
        type: string;
      }>;
}

export default function RichText({ text, ...props }: Props & TypographyProps) {
  const { data } = useQuery(LAYOUT_STATE_QUERY);

  let element = <Container colorScheme={data.layout.colorScheme} {...props} />;

  if (Array.isArray(text)) {
    element = cloneElement(element, {
      children: PrismicRichText.render(text, linkResolver),
    });
  } else if (typeof text === 'string') {
    element = cloneElement(element, {
      dangerouslySetInnerHTML: { __html: text },
    });
  }

  return element;
}

const Container = styled.div<
  TypographyProps & PaletteProps & { colorScheme: string }
>`
  ${compose(
    typography,
    palette,
  )}
  & ul {
    margin-left: 0;

    & li {
      margin-left: 0;
      margin-bottom: ${({ theme }) => `${theme.spacing(1)}px`};
    }
  }

  & p:not(:last-child) {
    margin-bottom: ${({ theme }) => `${theme.spacing(2)}px`};
  }

  a {
    text-decoration: none;
    color: ${({ theme, colorScheme }) => theme.palette[colorScheme].dark};
    &:hover {
      text-decoration: underline;
    }
  }

  h6 {
    font-size: ${({ theme }) => theme.typography.h6.fontSize};
    font-family: ${({ theme }) => theme.typography.h6.fontFamily};
    font-weight: ${({ theme }) => theme.typography.h6.fontWeight};
    letter-spacing: ${({ theme }) => theme.typography.h6.letterSpacing};
    line-height: ${({ theme }) => theme.typography.h6.lineHeight};
    margin: ${({ theme }) => theme.typography.h6.margin};
  }
`;
