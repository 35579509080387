import React, { CSSProperties } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';

import styled from 'styled-components';
import {
  css,
  display,
  compose,
  flexbox,
  palette,
  DisplayProps,
} from '@material-ui/system';
import { Typography } from '@material-ui/core';

import { linkResolver } from '../../../utils/prismic';

import SectionTitle from './SectionTitle';
import Panel from './Panel';
import Item from './Item';

interface Service {
  node: {
    navigation_section: string;
    navigation_label: string;
    navigation_description: string;
    _meta: {
      id: string;
      type: string;
      uid: string;
    };
  };
}

interface Services {
  [key: string]: Service[];
}

export default function Services() {
  const { prismic } = useStaticQuery(query);

  const defaultAcc: Services = {};

  const services: Services = [...prismic.allServices.edges].reduce(
    (acc, service: Service) => {
      acc[service.node.navigation_section] = acc[
        service.node.navigation_section
      ]
        ? [...acc[service.node.navigation_section], service]
        : [service];
      return acc;
    },
    defaultAcc,
  );

  return (
    <Container css={{ gridTemplateColumns: '1fr' }} display="grid">
      {Object.keys(services).map(key => (
        <Panel css={{ gridColumn: '1' }} key={key}>
          <SectionTitle m={1 / 2}>{key}</SectionTitle>
          {services[key].map(({ node }) => {
            return (
              <Item
                component={Link}
                key={node._meta.id}
                to={linkResolver({
                  type: node._meta.type,
                  uid: node._meta.uid,
                })}
              >
                <Text variant="subtitle2">{node.navigation_label}</Text>
                <Text variant="body2">{node.navigation_description}</Text>
              </Item>
            );
          })}
        </Panel>
      ))}
    </Container>
  );
}

const Container: React.ComponentType<
  { css: CSSProperties } & DisplayProps
> = styled.div`
  ${css(
    compose(
      display,
      flexbox,
    ),
  )}
`;

const Text = styled(Typography)`
  ${palette}
`;

export const query = graphql`
  query getAllServices {
    prismic {
      allServices {
        edges {
          node {
            _meta {
              id
              uid
              type
            }
            navigation_label
            navigation_section
            navigation_description
          }
        }
      }
    }
  }
`;
