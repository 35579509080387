import { PaletteColorOptions } from '@material-ui/core/styles/createPalette';

export const yellow: PaletteColorOptions = {
  dark: '#F5C758',
  main: '#F8E569',
  light: '#FFF29E',
};

export const blue: PaletteColorOptions = {
  dark: '#479DFF',
  main: '#72BCFC',
  light: '#A0D3FF',
};

export const grey: PaletteColorOptions = {
  dark: '#7194B8',
  main: '#95B4D4',
  light: '#BBD4EF',
};

export const green: PaletteColorOptions = {
  dark: '#2AAB5B',
  main: '#67E177',
  light: '#96EFA2',
};

interface CustomPaletteOptions {
  [key: string]: PaletteColorOptions | object;
  background: {
    lightGrey: string;
    contrast: {
      dark: string;
      main: string;
      light: string;
    };
  };
  text: {
    inverted: string;
  };
  gradients: {
    yellow: string;
    blue: string;
    grey: string;
    green: string;
  };
}

export const customPalette: CustomPaletteOptions = {
  background: {
    lightGrey: '#e7e7e7',
    contrast: {
      dark: '#3D4B64',
      main: '#55647E',
      light: '#687894',
    },
  },
  text: {
    inverted: '#fff',
  },
  yellow,
  blue,
  grey,
  green,
  gradients: {
    yellow: `linear-gradient(-61deg, ${yellow.light} 0%, ${yellow.dark} 100%)`,
    blue: `linear-gradient(-61deg, ${blue.light} 0%, ${blue.dark} 100%)`,
    grey: `linear-gradient(-61deg, ${grey.light} 0%, ${grey.dark} 100%)`,
    green: `linear-gradient(-61deg, ${green.light} 0%, ${green.dark} 100%)`,
  },
};

export const palette = {
  primary: yellow,
  secondary: blue,
  background: {
    default: '#f6f6f1',
  },
  divider: '#d8d8d8',
  common: {
    white: '#ffffff',
  },
  text: {
    primary: '#414141',
    secondary: '#6c6c6c',
  },
};
