import React from 'react';

import { ButtonBase } from '@material-ui/core';
import { typography, spacing, flexbox } from '@material-ui/system';
import styled from 'styled-components';

export default styled(function FlatColorButton({
  colorScheme,
  borderRadius,
  active,
  buttonRef,
  lineHeight,
  to,
  setActive,
  fullWidth,
  justifyContent,
  ...props
}) {
  const passthrough = { to, ...props };
  if (to) {
    passthrough.setActive = setActive;
  }
  return <ButtonBase buttonRef={buttonRef} variant="button" {...passthrough} />;
})`
  ${typography}
  ${spacing}
  ${flexbox}
  ${({ theme, active, colorScheme = 'yellow', fullWidth }) => `
    padding: ${theme.spacing(1)}px ${theme.spacing(2)}px;
    background-color: ${
      active ? theme.palette[colorScheme].main : 'transparent'
    };
    border-radius: ${theme.shape.borderRadius}px;
    color: ${active ? theme.palette.text.primary : 'inherit'}
    transition: ${theme.transitions.create('background-color', {
      duration: theme.transitions.duration.short,
    })};
    text-transform: uppercase;
    font-size: ${theme.typography.button.fontSize};
    font-weight: ${theme.typography.button.fontWeight};
    letter-spacing: ${theme.typography.button.letterSpacing};
    line-height: ${theme.typography.button.lineHeight};
    width: ${fullWidth ? '100%' : 'auto'};
  `}
`;
