/* eslint-disable no-restricted-globals */

export function getGlobal(this: any): Window & typeof globalThis {
  return (
    (typeof self === 'object' && self.self === self && self) ||
    (typeof global === 'object' && global.global === global && global) ||
    this
  );
}

export function getScrollY() {
  if (getGlobal().pageYOffset !== undefined) {
    return getGlobal().pageYOffset;
  } else {
    return (
      document.documentElement ||
      document.body.parentNode ||
      document.body
    ).scrollTop;
  }
}

export function getViewportWidth(): number {
  return (
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth
  );
}

export function getViewportHeight(): number {
  return (
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight
  );
}
