import React, { createContext, useState, useEffect } from 'react';

import useLocation from '../../utils/useLocation';

interface AuthenticationContext {
  user?: any;
  idToken?: string;
  accessToken?: string;
  expiresAt?: number;
  isAuthenticated: boolean;
  login: (x?: { email: string; password: string }) => void;
  logout?: () => void;
  signUp: (x: { email: string; password: string }) => Promise<any>;
  loading: boolean;
}

export interface Service {
  setLocationState: (x: { returnTo: string }) => void;
  login: <V>(x?: V) => Promise<any>;
  logout: () => Promise<any>;
  handleAuthentication: () => Promise<any>;
  silentAuth: () => Promise<any>;
  signUp: <V extends { [key: string]: string }>(x: V) => Promise<any>;
}

const defaultContext: AuthenticationContext = {
  loading: false,
  isAuthenticated: false,
  login: () => {},
  signUp: () => Promise.resolve(),
};

export const AuthenticationContext = createContext(defaultContext);

export default function Authentication<T, S extends Service>({
  children,
  service,
}: {
  children: T;
  service: S;
}): React.ReactElement | null {
  const [auth, setAuth] = useState({ user: {}, idToken: '' });
  const [loading, setLoading] = useState(false);

  const { location } = useLocation();

  const isCallback = location.pathname.search(/callback/g) !== -1;
  const preventRedirect = location.pathname.search(/login|sign-up/g) !== -1;
  const enforceLogin = process.env.GATSBY_STAGING === 'true';

  useEffect(() => {
    if (!isCallback && !preventRedirect) {
      service.setLocationState({ returnTo: location.pathname });
    }
  }, [location]);

  useEffect(() => {
    if (enforceLogin && !isCallback) {
      login();
    } else if (isCallback) {
      handleAuthentication();
    } else {
      silentAuth();
    }
  }, []);

  function login(values?: { email: string; password: string }): void {
    setLoading(true);
    service
      .login(values)
      .then(setAuth)
      .finally(() => setLoading(false));
  }

  function handleAuthentication(): void {
    setLoading(true);
    service
      .handleAuthentication()
      .then(setAuth)
      .finally(() => setLoading(false));
  }

  function silentAuth(): void {
    setLoading(true);
    service
      .silentAuth()
      .then(setAuth)
      .finally(() => setLoading(false));
  }

  function logout(): void {
    setLoading(true);
    service
      .logout()
      .then(setAuth)
      .finally(() => setLoading(false));
  }

  function signUp(values: { email: string; password: string }): Promise<any> {
    setLoading(true);
    return service
      .signUp(values)
      .then(login)
      .finally(() => setLoading(false));
  }

  return (
    <AuthenticationContext.Provider
      value={{
        ...auth,
        isAuthenticated: auth && !!auth.idToken,
        login,
        logout,
        signUp,
        loading,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}
