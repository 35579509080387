import { useEffect, useState } from 'react';
import { Search } from 'js-search';
import Snowball from 'snowball';
import { removeStopwords, fr } from 'stopword';

import { useQuery, useMutation, useApolloClient } from '@apollo/react-hooks';

import {
  SEARCH,
  FILTERS_SEARCH_QUERY,
  searchResolvers,
  FiltersData,
} from './localState';

export default function useSearch<R>({
  id,
  indexes,
  data: document = [],
}: {
  id: string;
  indexes: string[];
  data: R[];
}) {
  const client = useApolloClient();
  client.addResolvers(searchResolvers);

  const { data } = useQuery<FiltersData>(FILTERS_SEARCH_QUERY);

  const [updateQuery] = useMutation(SEARCH);

  const [jsSearch, setEngine] = useState();
  const [results, search] = useState<R[]>([]);

  useEffect(() => {
    if (id) {
      setEngine(new Search(id));
    }
  }, [id]);

  useEffect(() => {
    if (jsSearch && !jsSearch._initialized) {
      const tokenize = (text: string) =>
        text
          .split(/[^a-z0-9äâàéèëêïîöôùüûœç]+/i)
          .filter((isDefined: string) => !!isDefined);

      const stemmer = new Snowball('French');

      const stemmerize = (word: string) => {
        stemmer.setCurrent(word);
        stemmer.stem();
        return stemmer.getCurrent();
      };

      /**
       * Convert text into tokens.
       * Each token is then stemmed and passed along with the original word.
       * If only the stem is included in the index, the example below would
       * return nothing.
       *
       * token : communication
       * stem  : commun
       *
       * search input : communica
       *
       */
      jsSearch.tokenizer = {
        tokenize: (text: string) =>
          removeStopwords(tokenize(text), fr).reduce(
            (words: string[], word: string) => [
              ...words,
              word,
              stemmerize(word),
            ],
            [],
          ),
      };

      indexes.forEach(index => jsSearch.addIndex(index));
      jsSearch.addDocuments(document);
    }
  }, [jsSearch, document, indexes]);

  useEffect(() => {
    if (data && data.filters.query && jsSearch) {
      search(jsSearch.search(data.filters.query));
    } else {
      search(document);
    }
  }, [data, jsSearch]);

  return {
    searched: results,
    query: data ? data.filters.query : '',
    updateQuery: (query: string) => updateQuery({ variables: { query } }),
  };
}
