import React from 'react';

import { Formik, Form, Field, FieldProps } from 'formik';
import { Input, InputAdornment, IconButton } from '@material-ui/core';
import styled from 'styled-components';

import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

interface Props {
  onSubmit: (query: string) => void;
  query: string;
  maxWidth?: number;
}

export default function SearchForm({ onSubmit, query, maxWidth = 0 }: Props) {
  return (
    <Formik
      enableReinitialize
      initialValues={{ search: query }}
      onSubmit={({ search }) => onSubmit(search)}
    >
      <StyledForm maxWidth={maxWidth}>
        <Field name="search">
          {({ field }: FieldProps) => (
            <SearchInput
              {...field}
              autoCapitalize="off"
              autoComplete="off"
              autoCorrect="off"
              disableUnderline
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => onSubmit(query ? '' : field.value)}
                  >
                    {query ? <ClearIcon /> : <SearchIcon />}
                  </IconButton>
                </InputAdornment>
              }
              fullWidth
              id="search"
              placeholder="Rechercher une formation"
              spellCheck="false"
            />
          )}
        </Field>
      </StyledForm>
    </Formik>
  );
}

const StyledForm = styled(({ maxWidth, ...props }) => <Form {...props} />)`
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : 'unset')};
`;

const SearchInput = styled(({ borderRadius, maxWidth, ...props }) => (
  <Input {...props} />
))`
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  padding: 6px 16px;
`;
