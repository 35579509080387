import React, { forwardRef } from 'react';

import { Link as GatsbyLink } from 'gatsby';

interface Props {
  children?: React.ReactNode;
  forwardedRef: React.Ref<any>;
  setActive?: (x: boolean) => void;
  to: string;
}

function Link({ forwardedRef, setActive = () => {}, to, ...props }: Props) {
  return (
    <GatsbyLink
      getProps={({ isCurrent, isPartiallyCurrent }) => {
        setActive(to === '/' ? isCurrent : isPartiallyCurrent);
        return { ...props, to };
      }}
      ref={forwardedRef}
      to={to}
      {...props}
    />
  );
}

export default forwardRef((props: Props, ref) => (
  <Link forwardedRef={ref} {...props} />
));
