import gql from 'graphql-tag';
import { OperationVariables, Context } from '@apollo/react-common';

export const initialData = {
  layout: {
    colorScheme: 'yellow',
    maxWidth: 1240,
    headerHeight: 97,
    __typename: 'Layout',
  },
};

export const LAYOUT_STATE_QUERY = gql`
  query {
    layout @client {
      colorScheme
      maxWidth
      headerHeight
    }
  }
`;

export const CHANGE_COLOR_SCHEME = gql`
  mutation ChangeColorScheme($colorScheme: String!) {
    changeColorScheme(colorScheme: $colorScheme) @client
  }
`;

export const resolvers = {
  Mutation: {
    changeColorScheme: (
      _: any,
      { colorScheme }: OperationVariables,
      { cache }: Context,
    ) => {
      const data = cache.readQuery({ query: LAYOUT_STATE_QUERY });
      return cache.writeQuery({
        query: LAYOUT_STATE_QUERY,
        data: { ...data, layout: { ...data.layout, colorScheme } },
      });
    },
  },
};
