import { useQuery } from '@apollo/react-hooks';

import { Training } from '../Trainings/types';

import { FILTERS_QUERY, FiltersData } from './localState';

export default function useFilters(
  dataToFilter: Training[],
): { filtered: Training[] } {
  const { data, loading } = useQuery<FiltersData>(FILTERS_QUERY);

  if (!data || loading) {
    return { filtered: dataToFilter };
  }

  return {
    filtered: !!data.filters.products.length
      ? dataToFilter.filter(training => {
          return data.filters.products.includes(training.category.code);
        })
      : dataToFilter,
  };
}
