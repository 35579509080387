import { ApolloClient } from 'apollo-client';

import { initialData as layoutData } from '../components/Layout';
import { initialData as filterData } from '../components/Filters';

const data = {
  ...layoutData,
  ...filterData,
};

export default function initializeLocalState(client: ApolloClient<any>) {
  client.writeData({ data });
}
