import React from 'react';

import styled from 'styled-components';
import {
  color,
  display,
  flexbox,
  FlexboxProps,
  DisplayProps,
} from '@material-ui/system';

import { IconButton } from '@material-ui/core';

import { Facebook, LinkedIn, Twitter, Instagram } from '../Icons';

const Container = styled.div<DisplayProps & FlexboxProps>`
  ${color}
  ${display}
  ${flexbox}
`;

interface Props {
  color?: string;
}

export default function Socials({ color = 'text.primary' }: Props) {
  return (
    <Container alignItems="center" color={color} display="flex">
      <IconButton color="inherit">
        <Facebook />
      </IconButton>
      <IconButton color="inherit">
        <LinkedIn />
      </IconButton>
      <IconButton color="inherit">
        <Twitter />
      </IconButton>
      <IconButton color="inherit">
        <Instagram />
      </IconButton>
    </Container>
  );
}
