import React, { useState, forwardRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import styled from 'styled-components';
import {
  compose,
  css,
  palette,
  flexbox,
  spacing,
  borders,
  display,
  typography,
  SpacingProps,
  TypographyProps,
} from '@material-ui/system';

import { Button, ButtonBase, Typography } from '@material-ui/core';

import { sortByOrderProperty } from '../../../utils';

import Link from '../Link';

import { TrainingPrimary, Product, Solution } from '../../../types/training';

import RichText from '../../RichText';

import SectionTitle from './SectionTitle';
import Panel from './Panel';
import Item from './Item';

export default function Trainings() {
  const [selectedProduct, selectProduct] = useState<{
    uid?: string;
    name?: string;
    color: string;
  }>({ color: 'yellow' });
  const [selectedService, selectService] = useState<{ uid?: string }>({});
  const {
    prismic: { allProducts, allSolutions, allFeatureds },
  } = useStaticQuery(query);

  return (
    <Container display="grid">
      <Panel
        bgcolor="background.contrast.main"
        borderRadius="3px 0 0 3px"
        css={{ gridColumn: '1', gridRow: '1 / span 2' }}
      >
        <SectionTitle color="inherit">{"A l'affiche"}</SectionTitle>
        {allFeatureds.edges[0].node.body.map(({ primary }: TrainingPrimary) => (
          <TrainingBox
            alignItems="flex-start"
            bgcolor="background.contrast.light"
            borderRadius="small"
            color="common.white"
            component={forwardRef((props, ref) => (
              <Link
                {...props}
                forwardedRef={ref}
                to={`/formations/${primary.training.courseId}`}
              />
            ))}
            flexDirection="column"
            key={primary.training.courseId}
            mt={4}
            p={4}
          >
            <Text color="inherit" textAlign="left" variant="subtitle2">
              {primary.training.title}
            </Text>
            {primary.training.subtitle && (
              <Text color="inherit" mt="small" textAlign="left" variant="body1">
                {primary.training.subtitle}
              </Text>
            )}
          </TrainingBox>
        ))}
      </Panel>
      <Panel css={{ gridColumn: '2', gridRow: '1' }}>
        <SectionTitle m={1 / 2}>{'Produits'}</SectionTitle>
        {allProducts.edges
          .sort(sortByOrderProperty)
          .map(({ node: { color, name, title, _meta } }: Product) => (
            <Item
              active={selectedProduct.uid === _meta.uid}
              customColor={color}
              key={name}
              onClick={() => {
                if (selectedProduct.uid === _meta.uid) {
                  selectProduct({ color: 'yellow' });
                } else {
                  selectProduct({ uid: _meta.uid, color, name });
                }
              }}
            >
              <Text color="inherit" variant="subtitle2">
                {name}
              </Text>
              <Text color="inherit" variant="body1">
                {title}
              </Text>
            </Item>
          ))}
      </Panel>
      <Panel css={{ gridColumn: '3', gridRow: '1' }}>
        <SectionTitle m={1 / 2}>{'Solutions'}</SectionTitle>
        {allSolutions.edges
          .sort(sortByOrderProperty)
          .map(({ node: { title, description, _meta } }: Solution) => (
            <Item
              active={selectedService.uid === _meta.uid}
              customColor={selectedProduct.color}
              key={title}
              onClick={() => {
                if (selectedService.uid === _meta.uid) {
                  selectService({});
                } else {
                  selectService({ uid: _meta.uid });
                }
              }}
            >
              <Text color="inherit" variant="subtitle2">
                {title}
              </Text>
              <RichText text={description} />
            </Item>
          ))}
      </Panel>
      <CallToAction
        css={{
          gridColumn: '2 / span 2',
          gridRow: '2',
        }}
        pb="large"
        textAlign="center"
      >
        <StyledButton
          bgcolor={`${selectedProduct.color}.main`}
          variant="contained"
        >
          {`Voir ${
            selectedService.uid ? 'cette sélection du' : 'tout le'
          } catalogue ${selectedProduct.name ? selectedProduct.name : ''}`}
        </StyledButton>
      </CallToAction>
    </Container>
  );
}

const Container = styled.div`
  ${compose(
    display,
    flexbox,
  )}
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr auto;
`;

const CallToAction = styled.div<
  SpacingProps & TypographyProps & { css: React.CSSProperties }
>`
  ${css(
    compose(
      spacing,
      typography,
    ),
  )}
`;

const StyledButton = styled(props => <Button {...props} />)`
  ${palette}
`;

const TrainingBox = styled(
  ({ alignItems, borderRadius, flexDirection, ...props }) => (
    <ButtonBase {...props} />
  ),
)`
  ${compose(
    palette,
    borders,
    spacing,
    display,
    flexbox,
  )}
`;

const Text = styled(props => <Typography {...props} />)`
  ${compose(
    palette,
    spacing,
  )}
`;

const query = graphql`
  query getTrainingMenuData {
    prismic {
      allProducts {
        edges {
          node {
            _meta {
              uid
            }
            name
            title
            order
            legacy_id
            color
          }
        }
      }
      allSolutions {
        edges {
          node {
            _meta {
              uid
            }
            title
            order
            description
          }
        }
      }
      allFeatureds(where: { name: "Featured trainings" }) {
        edges {
          node {
            name
            body {
              ... on PRISMIC_FeaturedBodyTraining {
                type
                primary {
                  training
                }
              }
            }
          }
        }
      }
    }
  }
`;
