export default [
  '0.750rem',
  '0.875rem',
  '1rem',
  '1.25rem',
  '1.5rem',
  '2rem',
  '2.25rem',
  '3rem',
  '3.5rem',
];
