import React, { ReactNode } from 'react';

import styled from 'styled-components';
import { compose, palette, spacing, flexbox } from '@material-ui/system';
import { Button as MuiButton } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';

import { gradients } from '../../styles/functions';

interface Props {
  children: ReactNode;
  gradient?: string;
  variant?: 'text' | 'outlined' | 'contained';
}

const StyledButton = styled<React.FunctionComponent<ButtonProps & Props>>(
  MuiButton,
)`
  ${compose(
    gradients,
    palette,
    spacing,
    flexbox,
  )}
`;

export default function Button(props: Props) {
  return <StyledButton {...props} />;
}
