import React from 'react';

import { ListItem, ListItemIcon, Checkbox } from '@material-ui/core';

import styled from 'styled-components';

const StyledListItem = styled(ListItem)`
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
`;

const ListItemContent = styled.div`
  display: flex;
  flex-direction: column;
`;

interface Props {
  children?: React.ReactNode;
  onClick: React.MouseEventHandler;
  checked: boolean;
}

export default function FiltersSectionItem({
  children,
  onClick,
  checked,
}: Props) {
  return (
    <StyledListItem button onClick={onClick}>
      <ListItemIcon>
        <Checkbox checked={checked} disableRipple edge="start" tabIndex={-1} />
      </ListItemIcon>
      <ListItemContent>{children}</ListItemContent>
    </StyledListItem>
  );
}
