import React, { useState } from 'react';
import { Location } from '@reach/router';

import styled from 'styled-components';
import {
  compose,
  display,
  spacing,
  flexbox,
  sizing,
} from '@material-ui/system';

import { withWidth, IconButton, Drawer } from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

import MenuIcon from '@material-ui/icons/Menu';
import { isWidthUp } from '@material-ui/core/withWidth';

import { useAuthentication } from '../Authentication';

import NavButton from './NavButton';
import { Trainings, Services, Account } from './Menu';

interface Props {
  width: Breakpoint;
}

function Navigation({ width }: Props) {
  const { user, isAuthenticated } = useAuthentication();
  const [isOpen, open] = useState(false);
  const [openedMenu, openMenu] = useState();

  if (isWidthUp('md', width)) {
    return (
      <Location>
        {({ location }) => (
          <Items m={0} p={0}>
            <NavButton id="accueil" label="Accueil" link="/" />
            {/* <NavButton
              component={Trainings}
              id="formations"
              label="Formations"
              onClick={openMenu}
              open={openedMenu === 'formations'}
            /> */}
            <NavButton id="formations" label="Formations" link="/catalogue" />
            <NavButton
              component={Services}
              id="prestations"
              label="Préstations"
              onClick={openMenu}
              open={openedMenu === 'prestations'}
            />
            {isAuthenticated ? (
              <NavButton
                component={Account}
                id="account"
                label={user.name}
                onClick={openMenu}
                open={openedMenu === 'account'}
                state={{ returnTo: location.pathname }}
              />
            ) : (
              <NavButton id="account" label="Connexion" link="/login" />
            )}
          </Items>
        )}
      </Location>
    );
  }
  return (
    <>
      <StyledIconButton color="inherit" mr={2} onClick={() => open(true)}>
        <MenuIcon />
      </StyledIconButton>
      <Drawer onClose={() => open(false)} open={isOpen}>
        <Items
          display="flex"
          flexDirection="column"
          m={0}
          minWidth="250px"
          p={0}
        >
          <NavButton id="accueil" label="Accueil" link="/" />
          <NavButton
            component={Trainings}
            id="formations"
            label="Formations"
            onClick={openMenu}
            open={openedMenu === 'formations'}
          />
          <NavButton
            component={Services}
            id="prestations"
            label="Préstations"
            onClick={openMenu}
            open={openedMenu === 'prestations'}
          />
        </Items>
      </Drawer>
    </>
  );
}

const Items = styled.nav`
  ${compose(
    spacing,
    display,
    flexbox,
    sizing,
  )}
  margin-right: -${({ theme }) => theme.spacing(3)}px;
`;

const StyledIconButton = styled(IconButton)`
  ${spacing}
`;

export default withWidth()(Navigation);
