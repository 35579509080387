import React, { useState, useRef, MouseEventHandler } from 'react';

import { Match } from '@reach/router';
import { useQuery } from '@apollo/react-hooks';

import styled from 'styled-components';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { LAYOUT_STATE_QUERY } from '../Layout';

import { FlatColorButton } from '../Button';

import Link from './Link';
import Menu from './Menu';

const StyledIcon = styled(({ menuActive, ...props }) => (
  <KeyboardArrowDownIcon {...props} />
))`
  ${({ theme, menuActive }) => `
    transition: ${theme.transitions.create('transform')};
    transform: rotate(${menuActive ? '180deg' : 0});
    margin-left: ${theme.spacing(1)}px;
  `}
`;

interface Props {
  id: string;
  open?: boolean;
  onClick?: (id?: string) => void;
  link?: string;
  label: string;
  component?: React.ComponentType<{ onClose?: () => void }>;
  state?: object;
}

export default function NavButton({
  id,
  open = false,
  onClick = () => {},
  link = '',
  label,
  component,
  state = {},
}: Props) {
  const { data } = useQuery(LAYOUT_STATE_QUERY);
  const [active, setActive] = useState();
  const buttonRef = useRef<HTMLElement>();

  const handleOnClick: MouseEventHandler = event => {
    if (!link && open) {
      onClick();
    } else if (!link) {
      event.stopPropagation();
      event.preventDefault();
      onClick(id);
    }
  };

  return (
    <Match path={`${id}/*`}>
      {({ match }) => (
        <>
          <FlatColorButton
            active={active || (id !== 'accueil' && match)}
            buttonRef={buttonRef}
            colorScheme={data.layout.colorScheme}
            component={link ? Link : 'div'}
            mr={{ xs: 0, md: 3 }}
            onClick={handleOnClick}
            setActive={setActive}
            state={state}
            to={link}
          >
            {label}
            {component && <StyledIcon menuActive={open} />}
          </FlatColorButton>
          {component && buttonRef.current && (
            <Menu
              anchorEl={buttonRef.current}
              component={component}
              onClose={() => onClick()}
              open={open}
            />
          )}
        </>
      )}
    </Match>
  );
}
