import gql from 'graphql-tag';
import { OperationVariables, Context } from '@apollo/react-common';

export const initialData = {
  filters: {
    products: [],
    query: '',
    solutions: [],
    __typename: 'Filters',
  },
};

export const FILTERS_QUERY = gql`
  query getFilters {
    filters @client {
      products
      query
      solutions
    }
  }
`;

export interface FiltersData {
  filters: {
    products: string[];
    solutions: string[];
    query: string;
  };
}

export const FILTERS_PRODUCTS_QUERY = gql`
  query getProducts {
    filters @client {
      products
    }
  }
`;

export const FILTERS_SEARCH_QUERY = gql`
  query getQuery {
    filters @client {
      query
    }
  }
`;

export const FILTERS_SOLUTIONS_QUERY = gql`
  query getSolutions {
    filters @client {
      solutions
    }
  }
`;

export const TOGGLE_PRODUCT = gql`
  mutation ToggleProduct($product: String!) {
    toggleProduct(product: $product) @client
  }
`;

export const TOGGLE_SOLUTION = gql`
  mutation ToggleSolution($solution: String!) {
    toggleSolution(solution: $solution) @client
  }
`;

export const SEARCH = gql`
  mutation Search($query: String!) {
    search(query: $query) @client
  }
`;

export const DELETE_FILTERS = gql`
  mutation DeleteFilters {
    deleteFilters @client
  }
`;

interface ToggleElementInArray {
  (el: string, array: string[]): string[];
}

const toggleElementInArray: ToggleElementInArray = (el, array) => {
  const newArray = [...array];
  const index = newArray.indexOf(el);

  if (index === -1) {
    newArray.push(el);
  } else {
    newArray.splice(index, 1);
  }

  return newArray;
};

export const filtersResolvers = {
  Mutation: {
    toggleProduct: (
      _: any,
      { product }: OperationVariables,
      { cache }: Context,
    ) => {
      const { filters } = cache.readQuery({ query: FILTERS_QUERY });
      return cache.writeQuery({
        query: FILTERS_QUERY,
        data: {
          filters: {
            ...filters,
            products: toggleElementInArray(product, filters.products),
          },
        },
      });
    },
    toggleSolution: (
      _: any,
      { solution }: OperationVariables,
      { cache }: Context,
    ) => {
      const { filters } = cache.readQuery({ query: FILTERS_QUERY });

      return cache.writeQuery({
        query: FILTERS_QUERY,
        data: {
          filters: {
            ...filters,
            solutions: toggleElementInArray(solution, filters.solutions),
          },
        },
      });
    },
    deleteFilters: (_: any, _args: OperationVariables, { cache }: Context) => {
      cache.writeData({
        data: {
          filters: {
            products: [],
            query: '',
            solutions: [],
            __typename: 'Filters',
          },
        },
      });
    },
  },
};

export const searchResolvers = {
  Mutation: {
    search: (_: any, { query }: OperationVariables, { cache }: Context) => {
      const { filters } = cache.readQuery({ query: FILTERS_QUERY });
      return cache.writeQuery({
        query: FILTERS_QUERY,
        data: { filters: { ...filters, query } },
      });
    },
  },
};
