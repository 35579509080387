import { style, compose } from '@material-ui/system';

const backgroundImage = style({
  prop: 'backgroundImage',
  cssProperty: false,
  transform: src => `background-image: url(${src})`,
});

const backgroundSize = style({
  prop: 'backgroundSize',
});

const backgroundPosition = style({
  prop: 'backgroundPosition',
});

export default compose(
  backgroundImage,
  backgroundSize,
  backgroundPosition,
);
