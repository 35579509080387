export { default as ProductTabs } from './ProductTabs';
export { default as SearchForm } from './SearchForm';
export { default as Filters } from './Filters';
export { default as useSearch } from './useSearch';
export { default as useFilters } from './useFilters';

export {
  initialData,
  FILTERS_PRODUCTS_QUERY,
  FILTERS_SOLUTIONS_QUERY,
  TOGGLE_PRODUCT,
  FILTERS_QUERY,
  FILTERS_SEARCH_QUERY,
  SEARCH,
  searchResolvers,
  filtersResolvers,
} from './localState';
