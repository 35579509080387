import React, { createContext, useState } from 'react';

export const AppContext = createContext<{
  primaryColor?: string;
  setPrimaryColor?: React.Dispatch<React.SetStateAction<string>>;
}>({});

interface Props {
  children: React.ReactNode;
}

export default function AppContextProvider({ children }: Props) {
  const [primaryColor, setPrimaryColor] = useState('yellow');

  return (
    <AppContext.Provider value={{ primaryColor, setPrimaryColor }}>
      {children}
    </AppContext.Provider>
  );
}
