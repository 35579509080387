import React, { useState, useEffect } from 'react';

import {
  Collapse,
  Paper,
  Typography,
  List,
  ButtonBase,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import styled from 'styled-components';

const Container = styled(Paper)`
  background: transparent;
  border-top: ${({ theme }) => `1px solid ${theme.palette.divider}`};
`;

const SectionHeader = styled(ButtonBase)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 56px;
`;

const StyledIcon = styled(({ opened, ...props }) => (
  <ExpandMoreIcon {...props} />
))`
  transform: ${({ opened }) => (opened ? 'rotate(180deg)' : 'rotate(0)')};
  transition: ${({ theme }) => theme.transitions.create('transform')};
`;

const StyledList = styled(List)`
  padding: 0;
`;

interface Props {
  title: string;
  expanded?: boolean;
  children?: React.ReactNode;
}

export default function FiltersSection({
  title,
  children = [],
  expanded = false,
}: Props) {
  const [opened, toggle] = useState(false);

  useEffect(() => {
    toggle(expanded);
  }, []);

  return (
    <Collapse collapsedHeight="56px" in={opened}>
      <Container elevation={0} square>
        <SectionHeader onClick={() => toggle(!opened)}>
          <Typography color="textPrimary" variant="subtitle2">
            {title}
          </Typography>
          <StyledIcon opened={opened} />
        </SectionHeader>
        <StyledList>{children}</StyledList>
      </Container>
    </Collapse>
  );
}
