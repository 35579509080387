import auth0, {
  WebAuth,
  AuthOptions,
  Auth0ParseHashError,
  Auth0DecodedHash,
} from 'auth0-js';
import uuid from 'uuid';
import { navigate } from 'gatsby';

export interface AuthenticationResult extends Auth0DecodedHash {
  user: any;
  expiresAt: number;
}

export default class Auth0 {
  private isBrowser: boolean;
  private nonce: string;
  private locationState: object;
  private defaultRedirect: string;
  private clientID: string;
  private responseType: string;
  private redirectUri: string;
  private webAuth: WebAuth | false;

  constructor() {
    this.isBrowser = process.title === 'browser'; // Property is set by webpack in the browser
    this.nonce = uuid.v4();
    this.locationState = {};
    this.defaultRedirect = '/';

    this.clientID = process.env.GATSBY_AUTH0_CLIENT_ID || '';
    this.responseType = 'token id_token';
    this.redirectUri = process.env.GATSBY_AUTH0_CALLBACK || '';

    const authOptions: AuthOptions = {
      domain: process.env.GATSBY_AUTH0_DOMAIN || '',
      redirectUri: this.redirectUri,
      clientID: this.clientID,
      responseType: this.responseType,
      scope:
        'openid profile email read:current_user update:current_user_metadata',
      audience: 'https://crpm.eu.auth0.com/api/v2/',
    };

    this.webAuth = !!this.isBrowser && new auth0.WebAuth(authOptions);
  }

  private clean(redirect?: string) {
    redirect && navigate(redirect);
    return {};
  }

  private cleanupStorage() {
    for (let i = 0; i <= localStorage.length; i += 1) {
      const key = localStorage.key(i);
      if (key && key.search(/-4bd738b9f4a8$/) !== -1) {
        localStorage.removeItem(key);
      }
    }
    localStorage.removeItem('isLoggedIn');
  }

  private embeddedLogin({ email, password, realm }: { [key: string]: string }) {
    if (this.webAuth) {
      localStorage.setItem(
        `${this.nonce}-4bd738b9f4a8`,
        JSON.stringify(this.locationState),
      );

      this.webAuth.login({ email, password, realm, state: this.nonce }, () => {
        this.clean(this.defaultRedirect);
      });
    }
  }

  // private authorize() {
  //   if (this.webAuth) {
  //     localStorage.setItem(
  //       `${this.nonce}-4bd738b9f4a8`,
  //       JSON.stringify(this.locationState),
  //     );
  //     this.webAuth.authorize({ state: this.nonce });
  //   }
  // }

  private setSession(resolve: (value?: AuthenticationResult | {}) => void) {
    return (
      error: Auth0ParseHashError | null,
      authResult: Auth0DecodedHash | null,
    ) => {
      if (error) {
        return resolve(this.clean(this.defaultRedirect));
      }

      if (
        authResult &&
        authResult.accessToken &&
        authResult.idToken &&
        authResult.state
      ) {
        const localState = localStorage.getItem(
          `${authResult.state}-4bd738b9f4a8`,
        );

        /**
         * Cleanup state of nonces
         * This let us to clean eventual leftovers when a user start a login action
         * but doesn't fullfil the process (ex: close browser window on Auth0 login page)
         */
        this.cleanupStorage();

        if (!localState) {
          // Mitigate CSRF attack
          return this.clean(this.defaultRedirect);
        }

        const expiresAt = authResult.expiresIn
          ? authResult.expiresIn * 1000 + new Date().getTime()
          : new Date().getTime();

        localStorage.setItem('isLoggedIn', 'true');

        navigate(JSON.parse(localState).returnTo || this.defaultRedirect);

        resolve({
          user: authResult.idTokenPayload,
          accessToken: authResult.accessToken,
          idToken: authResult.idToken,
          expiresAt,
        });
      }
    };
  }

  public setLocationState(state: object) {
    this.locationState = state;
  }

  public login<V>(values?: V): Promise<any> {
    if (!this.isBrowser) {
      return Promise.resolve(this.clean());
    }

    if (localStorage.getItem('isLoggedIn') === 'true') {
      return this.silentAuth();
    }

    if (!values) {
      return Promise.resolve(this.clean('/login'));
    }

    return Promise.resolve(
      this.embeddedLogin({
        ...values,
        realm: 'Username-Password-Authentication',
      }),
    );
  }

  public logout() {
    return new Promise(resolve => {
      if (this.webAuth) {
        localStorage.removeItem('isLoggedIn');
        this.webAuth.logout({ returnTo: process.env.GATSBY_AUTH0_RETURN_TO });
      }
      return resolve(this.clean());
    });
  }

  public handleAuthentication() {
    return new Promise(resolve => {
      if (this.webAuth) {
        this.webAuth.parseHash(this.setSession(resolve));
      } else {
        resolve(this.clean());
      }
    });
  }

  public silentAuth() {
    return new Promise(resolve => {
      if (!this.webAuth || localStorage.getItem('isLoggedIn') !== 'true') {
        return resolve(this.clean());
      }

      localStorage.setItem(
        `${this.nonce}-4bd738b9f4a8`,
        JSON.stringify(this.locationState),
      );

      this.webAuth.checkSession(
        { state: this.nonce },
        this.setSession(resolve),
      );
    });
  }

  public signUp<V extends { [key: string]: string }>({
    email,
    password,
    givenName,
    familyName,
  }: V): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.webAuth) {
        this.webAuth.signup(
          {
            connection: 'Username-Password-Authentication',
            email,
            password,
            user_metadata: { givenName, familyName },
          },
          err => {
            if (err) {
              reject(err);
            }
            resolve({ email, password });
          },
        );
      } else {
        reject(
          "webAuth is not initialized. You're likely calling Auth0 on the server",
        );
      }
    });
  }
}
