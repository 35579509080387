import React from 'react';

import styled from 'styled-components';
import {
  compose,
  display,
  flexbox,
  spacing,
  sizing,
  borders,
  typography,
} from '@material-ui/system';

import { ButtonBase } from '@material-ui/core';

interface Props {
  children: React.ReactNode;
  active?: boolean;
  alignItems?: string;
  borderRadius?: string | number | object;
  customColor?: string;
  display?: string;
  flexDirection?: string;
  my?: string | number | object;
  onClick?: () => void;
  p?: string | number | object;
  textAlign?: string;
  width?: string | number | object;
  component?: React.ComponentType<{ to: string }>;
  to?: string;
}

export default function Item({
  children,
  alignItems = 'flex-start',
  borderRadius = 'borderRadius',
  flexDirection = 'column',
  textAlign = 'left',
  active = false,
  customColor = 'yellow',
  display = 'flex',
  my = 1,
  p = 1,
  width = '100%',
  ...props
}: Props) {
  return (
    <Container
      active={active}
      alignItems={alignItems}
      borderRadius={borderRadius}
      customColor={customColor}
      display={display}
      flexDirection={flexDirection}
      my={my}
      p={p}
      textAlign={textAlign}
      width={width}
      {...props}
    >
      {children}
    </Container>
  );
}

const Container = styled(
  ({
    alignItems,
    borderRadius,
    flexDirection,
    textAlign,
    active,
    customColor,
    display,
    my,
    p,
    width,
    ...props
  }) => <ButtonBase {...props} />,
)`
  ${compose(
    display,
    flexbox,
    spacing,
    sizing,
    borders,
    typography,
  )}
  color: ${({ theme, active, customColor }) =>
    active
      ? theme.palette.getContrastText(theme.palette[customColor].main)
      : theme.palette.text.primary};
  transition: ${({ theme }) =>
    theme.transitions.create('background-color', { duration: 150 })};
  background-color: ${({ theme, active, customColor }) =>
    active ? theme.palette[customColor].main : 'transparent'};
  :hover {
    background-color: ${({ theme, active, customColor }) =>
      active
        ? theme.palette[customColor].main
        : theme.palette.background.lightGrey};
  }
`;
