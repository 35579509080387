import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { useQuery } from '@apollo/react-hooks';
import Img from 'gatsby-image';

import styled from 'styled-components';
import {
  compose,
  spacing,
  display,
  positions,
  sizing,
  flexbox,
  borders,
  typography,
  palette,
  BordersProps,
  PaletteProps,
  SpacingProps,
  DisplayProps,
  FlexboxProps,
} from '@material-ui/system';

import { Typography } from '@material-ui/core';

import { sortByOrderProperty } from '../../utils';

import { Product } from '../../types/training';

import Socials from '../Socials';
import { LAYOUT_STATE_QUERY } from '../Layout';

export default function Footer() {
  const {
    logo,
    prismic: { allProducts },
  } = useStaticQuery(query);

  const { data } = useQuery(LAYOUT_STATE_QUERY);

  return (
    <Wrapper bgcolor="background.lightGrey" borderColor="divider">
      <Container
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        maxWidth={data.layout.maxWidth}
        mx="auto"
        position="relative"
        pt={{ xs: 7, sm: 14 }}
        px={{ xs: 2, md: 3 }}
      >
        <Container
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          pb={14}
        >
          <Column flex="0 0 25%" p={2}>
            <Typography variant="h6">{'FORMATIONS'}</Typography>
            {allProducts.edges
              .sort(sortByOrderProperty)
              .map(({ node }: Product) => (
                <StyledLink
                  fontSize="body1.fontSize"
                  key={node.name}
                  to={`/${node._meta.uid}`}
                >
                  {node.title}
                </StyledLink>
              ))}
          </Column>
          <Column flex="0 0 25%" p={2}>
            <Typography variant="h6">{'PRESTATIONS'}</Typography>
          </Column>
          <Column flex="0 0 25%" p={2}>
            <Typography variant="h6">{'ACTUALITES'}</Typography>
          </Column>
          <Column flex="0 0 25%" p={2}>
            <Typography variant="h6">{'A NOTRE SUJET'}</Typography>
          </Column>
        </Container>
        <Container
          borderColor="divider"
          borderTop={1}
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          py={4}
        >
          <Img fixed={logo.childImageSharp.fixed} />
          <Column display="flex" flexDirection="column" justifyContent="center">
            <Typography variant="body2">{'Av. Agassiz 5'}</Typography>
            <Typography variant="body2">{'CP 369 - 1001 Lausanne'}</Typography>
          </Column>
          <Column display="flex" flexDirection="column" justifyContent="center">
            <Typography variant="body2">{'+41 21 341 31 50'}</Typography>
            <Typography variant="body2">{'info@crpm.ch'}</Typography>
          </Column>
          <Socials />
        </Container>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div<BordersProps & PaletteProps>`
  ${borders}
  ${palette}
`;

const Container = styled.div`
  ${compose(
    spacing,
    sizing,
    display,
    flexbox,
    positions,
    borders,
  )}
`;

const Column = styled.div<
  PaletteProps & SpacingProps & DisplayProps & FlexboxProps
>`
  ${compose(
    palette,
    spacing,
    display,
    flexbox,
  )}
`;

const StyledLink = styled(Link)`
  ${typography}
  display: inline-block;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  &:not(:last-of-type) {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
`;

const query = graphql`
  query footerQuery {
    logo: file(name: { regex: "/logo/" }) {
      childImageSharp {
        fixed(width: 96) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    prismic {
      allProducts {
        edges {
          node {
            _meta {
              uid
            }
            name
            title
            order
            legacy_id
            color
          }
        }
      }
    }
  }
`;
