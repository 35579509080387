import React from 'react';

import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import { Typography, Button } from '@material-ui/core';

import styled from 'styled-components';

import { useStaticQuery, graphql } from 'gatsby';

import RichText from '../RichText';

import { sortByOrderProperty } from '../../utils';

import { Product, Solution } from '../../types/training';

import FiltersSection from './FiltersSection';
import FiltersSectionItem from './FiltersSectionItem';

import {
  FILTERS_QUERY,
  TOGGLE_SOLUTION,
  TOGGLE_PRODUCT,
  DELETE_FILTERS,
  filtersResolvers,
  FiltersData,
} from './localState';

export default function Filters() {
  const client = useApolloClient();
  client.addResolvers(filtersResolvers);
  const { prismic } = useStaticQuery(query);

  const [toggleSolution] = useMutation(TOGGLE_SOLUTION);
  const [toggleProduct] = useMutation(TOGGLE_PRODUCT);
  const [deleteFilters] = useMutation(DELETE_FILTERS);

  const { data, loading } = useQuery<FiltersData>(FILTERS_QUERY);

  if (!data || loading) {
    return <>{'Loading filters'}</>;
  }

  const { products, solutions } = data.filters;

  return (
    <Container>
      <Typography gutterBottom variant="h4">
        {'Filtres'}
      </Typography>
      <StyledButton
        color="primary"
        disabled={
          (!products || !products.length) && (!solutions || !solutions.length)
        }
        fullWidth
        onClick={() => deleteFilters()}
        size="small"
        variant="contained"
      >
        {'Enlever tous les filtres'}
      </StyledButton>
      <FiltersSection
        expanded={!!products && !!products.length}
        title={'Produits'}
      >
        {prismic.allProducts.edges
          .sort(sortByOrderProperty)
          .map(({ node }: Product) => (
            <FiltersSectionItem
              checked={products.includes(node.tms_reference)}
              key={node.tms_reference}
              onClick={() =>
                toggleProduct({ variables: { product: node.tms_reference } })
              }
            >
              <Title color="textPrimary" variant="body1">
                {node.name}
              </Title>
              <Typography variant="body1">{node.title}</Typography>
            </FiltersSectionItem>
          ))}
      </FiltersSection>
      <FiltersSection expanded={!!solutions.length} title={'Solutions'}>
        {prismic.allSolutions.edges.map(({ node }: Solution) => (
          <FiltersSectionItem
            checked={solutions.includes(node._meta.id)}
            key={node._meta.id}
            onClick={() =>
              toggleSolution({ variables: { solution: node._meta.id } })
            }
          >
            <Title color="textPrimary" variant="body1">
              {node.title}
            </Title>
            <Description text={node.description} />
          </FiltersSectionItem>
        ))}
      </FiltersSection>
    </Container>
  );
}

const Container = styled.div`
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.divider}`};
`;

const StyledButton = styled(Button)`
  margin: 16px 0;
`;

const Title = styled(Typography)`
  font-weight: 600;
  font-size: 14px;
  padding-bottom: 8px;
`;

const Description = styled(RichText)`
  & p {
    margin: 0;
    font-size: 14px;
  }
`;

export const query = graphql`
  query {
    prismic {
      allSolutions {
        edges {
          node {
            title
            description
            order
            _meta {
              id
            }
          }
        }
      }
      allProducts {
        edges {
          node {
            name
            title
            tms_reference
            order
            color
          }
        }
      }
    }
  }
`;
