type Doc = {
  type?: string;
  uid?: string;
};

export function linkResolver(doc: Doc = {}) {
  if (doc.type === 'service') {
    return `/prestations/${doc.uid}`;
  } else if (doc.type === 'homepage') {
    return `/`;
  }

  return '/';
}
