import { useState, useEffect } from 'react';
import { globalHistory } from '@reach/router';

export default function useLocation() {
  const initialState = {
    location: globalHistory.location,
    navigate: globalHistory.navigate,
  };

  const [state, setState] = useState(initialState);

  useEffect(() => {
    const removeListener = globalHistory.listen(params => {
      const { location } = params;
      setState({
        ...initialState,
        location: { ...initialState.location, ...location },
      });
    });
    return () => {
      removeListener();
    };
  }, []);

  return state;
}
