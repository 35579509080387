import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import merge from 'deepmerge';

import { customPalette, palette } from './colors';
import { customTypography, typography } from './typography';
import overrides from './overrides';

export default responsiveFontSizes(
  merge(
    createMuiTheme({
      palette,
      typography,
      overrides,
    }),
    {
      palette: customPalette,
      typography: customTypography,
    },
  ),
);
