import React, { CSSProperties } from 'react';
import styled from 'styled-components';

import {
  sizing,
  spacing,
  compose,
  css,
  borders,
  palette,
  PaletteProps,
  BordersProps,
  SpacingProps,
  SizingProps,
} from '@material-ui/system';

const Container = styled.div`
  ${css(
    compose(
      borders,
      palette,
      spacing,
      sizing,
    ),
  )}
`;

interface Props extends PaletteProps, BordersProps, SpacingProps, SizingProps {
  children: React.ReactNode;
  css: CSSProperties;
}

export default function Panel({
  bgcolor = 'common.white',
  borderRadius = 0,
  children = null,
  css = { gridRow: 'auto' },
  p = 4,
  width = '336px',
  ...props
}: Props) {
  return (
    <Container
      bgcolor={bgcolor}
      borderRadius={borderRadius}
      css={css}
      p={p}
      width={width}
      {...props}
    >
      {children}
    </Container>
  );
}
