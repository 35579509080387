import React, { forwardRef } from 'react';

import styled from 'styled-components';
import { Typography, ButtonBase } from '@material-ui/core';

const TabContainer = styled(props => <ButtonBase {...props} />)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-transform: none;
  padding: 24px 0;
  margin: 0 24px;
`;

interface Props {
  name: string;
  title: string;
}

const ProductTab = forwardRef(({ name, title, ...tabProps }: Props, ref) => (
  <TabContainer {...tabProps} ref={ref}>
    <Typography align="left" color="textPrimary" variant="h5">
      {name}
    </Typography>
    <Typography align="left" color="textPrimary" variant="body1">
      {title}
    </Typography>
  </TabContainer>
));

export default ProductTab;
